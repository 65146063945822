*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    
    background: #1e1a20;
    font-family: 'Inconsolata', monospace;
    /* background-color: #eeeeee; */
}
.content {
    margin:20px;
    padding:20px;
    color:aliceblue;
    z-index: 9999;
    position: absolute;
    backdrop-filter: blur(3px) contrast(100%);
    -webkit-backdrop-filter: blur(3px) contrast(100%);
}
.contactWrap {
    position: absolute;
    display: flex;
    left:0;
    width: 100%;
}
.contact, .about {
    margin: 0 auto;
    color: #fff;
    padding: 30px;
}
.contact {
    margin-top: 6%;
    backdrop-filter: blur(7px) contrast(100%);
    -webkit-backdrop-filter: blur(7px) contrast(100%);
}
.about h2 {
    border-bottom: 3px solid rgb(22, 149, 223);
}
.about h3 {
    background: rgba(255, 255, 255,0.9);
    display: inline-flex;
    padding: 5px 10px;
    color: rgb(0, 0, 0);
    text-shadow: rgb(198 249 94) -2px 0px;
    margin-bottom: 10px;
    border-right: 4px solid #000;
    border-bottom: 4px solid #000;
}
.about p{
    margin-left: 35px;
}
.about {
    margin-top: -30px;
    backdrop-filter: brightness(0.3) contrast(0.8);
    -webkit-backdrop-filter: brightness(0.3) contrast(0.8);
    box-shadow: 8px 8px 5px -11px #fff;
}
.contactWrap  li{
    margin-bottom: 20px;
}
.contactWrap ul {
    list-style: none;
    margin-top: 20px;
}
.btn {
    margin-top:10px;
    background-color: rgb(22, 149, 223);
    padding:5px 10px;
    cursor:pointer;
    border-radius: 5px;
    border:1px solid #444;
    color: #fff;
    font-family: 'Inconsolata', monospace;
    transition:200ms;
    transition-duration: 200ms;
}
.btn:hover {
    background-color: #1e1a20;
}
.bottom {
    position: fixed;
    bottom: 10px;
    right:10px;
}
.hide {
    display: none;
}
a {
    text-decoration: none;
    color: #fff;
}
.webgl
{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}

@media (min-width:800px) {
    .about h2 {
        font-size: 3em;
        margin-bottom: 20px;
    }
}
@media (max-width:800px) {
    .content {
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
    }
}
@media (max-width:480px) { 
    .content {
        margin: -5px 0 0 0;
    }
    .about {
        margin-top:-50px;
        padding:10px 5px 5px 20px;
    }
    .about h2 {
        font-size: 1em;
    }
    .about h3 {
        font-size: 0.85em
    }
    
}
@media (max-width: 300px) {
    h2 {
        font-size: small;
    }
} 
@media (max-width: 240px) {
    h1{
        font-size: smaller;
    }
} 